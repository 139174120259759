import { Signal } from "@preact/signals-react";
import React, { useEffect, useRef, KeyboardEvent, FormEvent } from "react";
import { useSignals } from "@preact/signals-react/runtime";

const TextInput = ({
  label,
  signal,
  onEnter,
  onEscape,
  autoFocus,
  ...props
}: {
  label?: React.ReactNode;
  signal: Signal<string>;
  onEnter?: (_event: KeyboardEvent<HTMLInputElement>) => void;
  onEscape?: (_event: KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  [key: string]: any;
}) => {
  useSignals();
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, autoFocus]);
  const onInput = (_event: FormEvent<HTMLInputElement>) => {
    // FIXME: This should fire somewhat on a timer
    signal.value = ref.current?.value ?? "";
  };
  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.shiftKey || event.altKey || event.ctrlKey || event.metaKey) {
      return;
    }
    if (event.key === "Enter" && onEnter) {
      event.preventDefault();
      onEnter(event);
    }
    if (event.key === "Escape" && onEscape) {
      event.preventDefault();
      onEscape(event);
    }
  };
  const input = (
    <input
      className="p-1 w-full my-1"
      type="text"
      onInput={onInput}
      value={signal.value}
      onKeyDown={onKeyDown}
      ref={ref}
      {...props}
    />
  );
  if (label) {
    return (
      <label>
        <div className="text-sm font-semibold">{label}</div>
        {input}
      </label>
    );
  } else {
    return input;
  }
};

export default TextInput;
