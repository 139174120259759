import React from "react";
import { twMerge } from "tailwind-merge";

export type NavLinkProps = {
  href: string;
  children: React.ReactNode;
  className?: string;
  blank?: boolean;
  disabled?: boolean;
  primary?: boolean;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export function NavLink({
  href,
  children,
  className,
  blank,
  disabled,
  primary,
  ...props
}: NavLinkProps) {
  if (disabled) {
    return (
      <span className="text-gray-600 font-bold py-2 px-4 rounded border border-blue-400 cursor-not-allowed">
        {children}
      </span>
    );
  }
  let cls = twMerge(
    "text-blue-700 hover:text-blue-900 hover:underline hover:bg-blue-100 font-bold py-2 px-4 rounded border border-blue-400",
    className,
  );
  if (primary) {
    cls = twMerge(
      "text-white bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded",
      className,
    );
  }
  return (
    <a className={cls} target={blank ? "_blank" : undefined} href={href} {...props}>
      {children}
    </a>
  );
}

export function A({
  href,
  children,
  blank,
  className,
  ...props
}: {
  href: string;
  children: React.ReactNode;
  blank?: boolean;
  className?: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  const cls = twMerge(
    "text-blue-700 hover:text-blue-900 hover:underline hover:bg-blue-100",
    className,
  );
  return (
    <a className={cls} href={href} target={blank ? "_blank" : undefined} {...props}>
      {children}
    </a>
  );
}
